/* App.css */
/* CSS for Background Animation */
body {
  margin: 0;
  padding: 0;

  animation: gradient 10s linear infinite;
  background: linear-gradient(45deg, #3498db, #9b59b6, #e74c3c, #f1c40f);
  background-size: 400% 400%;
}




.widget-container {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Widget Title */
.widget-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* URL Input */
.url-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}

/* Check Button */
.check-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.check-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

/* Loading Indicator */
.loading-indicator {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  color: #3498db;
}

/* Result Display */
.result-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

/* Mobile-Friendly Styles */
@media (max-width: 768px) {
  .widget-title {
    font-size: 20px;
  }
  .url-input {
    font-size: 14px;
  }
  .check-button {
    font-size: 16px;
  }
  .loading-indicator {
    font-size: 14px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}




